<template>
  <div>
    <ModalFreeShipping />
    <div class="header-table">
      <b-row></b-row>

      <div class="flex-sale">
        <b-form-group label-for="type">
          <BaseButton @click="openModal" variant="primary">
            Configurar nova região
          </BaseButton>
        </b-form-group>
      </div>
    </div>

    <b-row class="Table-header mb-1" v-if="!isMobile">
      <b-col cols="1" md="2"> #ID </b-col>
      <b-col cols="3" md="2"> Nome </b-col>
      <b-col cols="3" md="5"> Aplica em: </b-col>
      <b-col cols="2" md="1" class=""> Ações </b-col>

    </b-row>

    <b-row class="Table-header mb-1" v-if="isMobile">
      <b-col cols="1" md="2"> #ID </b-col>
      <b-col cols="3" md="4"> Nome </b-col>
      <b-col cols="4" md="4"> Aplica em: </b-col>
    </b-row>

    <template v-if="!loading && free_shipping.length > 0 && isMobile">
      <b-row class="Table-body" v-for="item in free_shipping" :key="item.id">
        <b-col cols="1" md="2" >
          {{ item.id }}
        </b-col>

        <b-col cols="4" md="4" >
          {{ item.name }}
        </b-col>

        <b-col cols="3" md="4" >
          {{ item.zone_start_zip_code }}
        </b-col>

        
      </b-row>
    </template>


    <template v-if="!loading && free_shipping.length > 0 && !isMobile">
      <b-row class="Table-body" v-for="item in free_shipping" :key="item.id">
        <b-col
          cols="1"
          md="2" 
          id="id_venda"
          v-b-tooltip="{
            title: item.status,
            placement: 'bottom',
          }"
        >
          # {{ item.id }}
        </b-col>

        <b-col cols="2" md="2" >
          {{ item.name }}
        </b-col>     
        
          

        <b-col cols="3" md="5" class="status">
          <div v-if="item.type=='REGION'">
            {{ item.region  }}
          </div>
          <div v-else>
            {{ item.zone_start_zip_code  }} -
            {{ item.zone_end_zip_code  }}
          </div>
        </b-col>      

        <b-col cols="1" md="1" class="status">
          <BaseButton class="button-outline-danger" @click="confirmRemove(item.id)" variant="danger"  >
           Remover
          </BaseButton>
        </b-col>
      </b-row>
    </template>

 
    <!-- Pagination -->
    <b-row>
      <b-col>
        <Paginate
          v-if="free_shipping.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :items-per-page="pagination.perPage"
          :justify_start="true"
          :isPurchase="true"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>
    <div
      v-if="loading && !free_shipping.length"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <b-row
      v-if="!loading && !free_shipping.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhuma região foi encontrada</p>
    </b-row>
  </div>
</template>

<script>
import Paginate from "@/components/base/Paginate";
import ModalFreeShipping from "@/components/ModalFreeShipping";
import Money from "@/mixins/money";
import FreeShippingService from "@/services/resources/FreeShippingService";
import Vue from "vue";

const service = FreeShippingService.build();

export default {
  props:['isMobile'],
  components: {
    Paginate,
    ModalFreeShipping,
  },
  mixins: [Money],
  data() {
    return {
      intervalid1: "",
      search: "",
      loading: false,
      free_shipping: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  mounted() {
    this.fetchfreeShippings();
    this.$root.$on("fetchfreeShippings", () => {
      this.fetchfreeShippings();
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
  },
  methods: {
    setLogo() {},
    convertStatus(e) {
      if (e === 'published') {
        return { name:"Publicado", color:"#2133D2" };
      }else if(e === 'outdated'){
        return { name:"Expirado", color:"#ff0c37" };
      }
    },
    openModal() {
      this.$root.$emit("bv::show::modal", "modal-free-shipping", "#btnShow");
    },
    fetchfreeShippings(loading) {
      if(!loading){
        this.loading = true;
      }
      this.pagination.totalPages = 1;
      service
        .read("?page=" + this.pagination.currentPage + "&per_page=" + this.pagination.perPage)
        .then((e) => {
          console.log(e);
          this.free_shipping = e.data;
          if(this.pagination.currentPage > e.last_page){
            this.toPage(e.last_page);
          }
          this.pagination.totalPages = e.last_page;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    revokeToken(data) {
      localStorage.setItem('revoke',data.id);
      this.$root.$emit("revoke", data);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchfreeShippings();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchfreeShippings();
    },
    confirmRemove(id){
      Vue.swal({
        title: "Você tem certeza ?",
        text: `Essa ação não poderá ser desfeita.`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        icon: 'warning',
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.remove(id)
        }
      });
    },
    remove(id){
      service
        .destroy(id)
        .then((e) => {
          this.fetchfreeShippings();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }

  },
};
</script>

<style lang="scss" scoped>
img.img-table {
  width: 75px;
}
</style>