<template>
  <BaseModal
    name="modal-free-shipping"
    size="lg"
    title="Configure uma nova região"
    @hidden="closeModal"
    @shown="openModal"
  >
    <b-row class="ml-1">
      <p class="title-item mb-0">01.</p>
      <p class="text-description mb-0 ml-3">Informe o nome da Região</p>
    </b-row>
    <!-- <div class="d-flex justify-content"> -->
    <b-form-group label-for="name" class="mt-4">
      <b-form-input
        id="name"
        placeholder="Digite o nome da Região"
        type="text"
        name="name"
        v-model="formData.name"
      ></b-form-input>
    </b-form-group>

    <b-row class="ml-1">
      <p class="title-item mb-0">02.</p>
      <p class="text-description mb-0 ml-3">Configurar por?</p>
    </b-row>
    <div data-anima="top"  class="mt-4 mb-4">
        <b-form-select
          v-model="formData.type"
          value-field="value"
          text-field="text"
          :options="selectOptions"
          v-validate="'required'"
          name="type"
        ></b-form-select>
      </b-form-group>
    </div>
    <div v-if="formData.type=='ZIP_CODE_RANGE'">
      <b-row class="ml-1">
        <p class="title-item mb-0">03.</p>
        <p class="text-description mb-0 ml-3">Informe a Faixa inicial do CEP</p>
      </b-row>
      <!-- <div class="d-flex justify-content"> -->
      <b-form-group label-for="zone_start_zip_code" class="mt-4">
        <b-form-input
          id="zone_start_zip_code"
          placeholder="Faixa do CEP de:"
          type="text"
          name="zone_start_zip_code"
          v-mask="'#####-###'"
          v-model="formData.zone_start_zip_code"
        ></b-form-input>
      </b-form-group>

      <b-row class="ml-1">
        <p class="title-item mb-0">04.</p>
        <p class="text-description mb-0 ml-3">Informe a Faixa final do CEP</p>
      </b-row>
      <!-- <div class="d-flex justify-content"> -->
      <b-form-group label-for="zone_end_zip_code" class="mt-4">
        <b-form-input
          id="zone_end_zip_code"
          placeholder="Faixa do CEP até:"
          type="text"
          name="zone_end_zip_code"
          v-mask="'#####-###'"
          v-model="formData.zone_end_zip_code"
        ></b-form-input>
      </b-form-group>
    </div>
    <div v-else>
      <b-row class="ml-1">
        <p class="title-item mb-0">03.</p>
        <p class="text-description mb-0 ml-3">Selecione o estado</p>
      </b-row>
      <div data-anima="top" class="mt-4">
        <b-form-select
          v-model="formData.region"
          value-field="value"
          text-field="text"
          :options="selectOptionsStates"
          v-validate="'required'"
          name="type"
        ></b-form-select>
      </b-form-group>
    </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel"> Fechar </BaseButton>
      <BaseButton class="aling-button" @click="create()" variant="black"
        >Salvar</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/base/Modal";
import FreeShippingService from "@/services/resources/FreeShippingService";
const service = FreeShippingService.build();
export default {
  components: {
    BaseModal,
  },
  data() {
    return {
      formData: {
        name: "",
        zone_start_zip_code: "",
        zone_end_zip_code: "",
        type: "REGION",
        region: 'Acre'
      },
      loading: false,
      dataServices: [{ active: true, name: "MelhorEnvio" }],
      selectedOption: "REGION",
      selectOptions: [
        { value: "REGION", text: "Estado" },
        { value: "ZIP_CODE_RANGE", text: "Faixa do Cep" },
      ],
      selectOptionsStates: [
        { value: "Acre", text: "Acre (AC)" },
        { value: "Alagoas", text: "Alagoas (AL)" },
        { value: "Amapá", text: "Amapá (AP)" },
        { value: "Amazonas", text: "Amazonas (AM)" },
        { value: "Bahia", text: "Bahia (BA)" },
        { value: "Ceará", text: "Ceará (CE)" },
        { value: "Distrito Federal", text: "Distrito Federal (DF)" },
        { value: "Espírito Santo", text: "Espírito Santo (ES)" },
        { value: "Goiás", text: "Goiás (GO)" },
        { value: "Maranhão", text: "Maranhão (MA)" },
        { value: "Mato Grosso", text: "Mato Grosso (MT)" },
        { value: "Mato Grosso do Sul", text: "Mato Grosso do Sul (MS)" },
        { value: "Minas Gerais", text: "Minas Gerais (MG)" },
        { value: "Pará", text: "Pará (PA)" },
        { value: "Paraíba", text: "Paraíba (PB)" },
        { value: "Paraná", text: "Paraná (PR)" },
        { value: "Pernambuco", text: "Pernambuco (PE)" },
        { value: "Piauí", text: "Piauí (PI)" },
        { value: "Rio de Janeiro", text: "Rio de Janeiro (RJ)" },
        { value: "Rio Grande do Norte", text: "Rio Grande do Norte (RN)" },
        { value: "Rio Grande do Sul", text: "Rio Grande do Sul (RS)" },
        { value: "Rondônia", text: "Rondônia (RO)" },
        { value: "Roraima", text: "Roraima (RR)" },
        { value: "Santa Catarina", text: "Santa Catarina (SC)" },
        { value: "São Paulo", text: "São Paulo (SP)" },
        { value: "Sergipe", text: "Sergipe (SE)" },
        { value: "Tocantins", text: "Tocantins (TO)" }
      ],
    };
  },
  mounted() {},
  methods: {
    openModal() {},
    closeModal() {
      this.$root.$emit("bv::hide::modal", "modal-free-shipping", "#btnShow");
      this.formData.name = "";
      this.formData.zone_start_zip_code = "";
      this.formData.zone_end_zip_code = "";
      this.formData.type = "REGION";
      this.formData.region = "Acre";

    },
    create() {
      // Chame o método do seu serviço para criar os dados
      service
        .create(this.formData)
        .then((response) => {
          this.$bvToast.toast("Zona de frete grátis criada com sucesso!", {
            title: "Frete grátis!",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.closeModal();
          this.$root.$emit("fetchfreeShippings");
        })
        .catch((error) => {
          console.error("Erro ao enviar os dados:", error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.size-card-ml {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2133d2;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
  img {
    width: 100%;
    position: relative;
    top: 10px;
  }
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}
</style>
